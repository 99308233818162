:root {
    --background-color: #c8c8c8 !important;
    --slskd-browse-folderlist-header-color:  black;
    --slskd-browse-folderlist-header-selected-color: #0E6EB8;
}

:root.dark {
    --slskd-primary-background: #0d1116;
    --slskd-secondary-background: #161b21;
    --slskd-overlay-background: #161b22;
    --slskd-tertiary-background: #21262d;
    --slskd-tertiary-border-color: rgba(240,246,252,0.1);
    --slskd-subtle-background: rgba(110,118,129,0.1);
    --slskd-color: #c9d1d9;
    --slskd-color-subtle: #6e7681;
    --slskd-hover-background: #30363d;
    --slskd-color-inset: #010409;
    --slskd-emphasis-background: #1f6feb;

    --background-color: var(--slskd-primary-background) !important;

    /* globals/site */
    --smui-site-body-text-color: var(--slskd-color);
    --smui-site-input-color: var(--smui-site-body-text-color);
    --smui-site-link-color: #58a6ff;
    --smui-site-link-hover-underline: underline;

    --slskd-browse-folderlist-header-color: var(--slskd-color);
    --slskd-browse-folderlist-header-selected-color: var(--smui-site-link-color);

    /* element/button */
    --smui-button-background-color: var(--slskd-tertiary-background);
    --smui-button-text-color: var(--smui-site-body-text-color);
    --smui-button-border: 1px solid var(--slskd-tertiary-border-color);
    --smui-button-hover-background-color: var(--slskd-hover-background);

    /* element/input */
    --smui-input-background: var(--slskd-color-inset);
    --smui-input-focus-color: var(--smui-site-body-text-color);

    /* element/label */
    --smui-label-background-color: var(--slskd-tertiary-background);
    --smui-label-color: var(--smui-site-body-text-color);

    /* collections/menu */
    --smui-menu-inverted-background: var(--slskd-secondary-background);
    --smui-menu-active-item-text-color: var(--smui-site-body-text-color);

    /* element/segment */
    --smui-segment-background: var(--slskd-primary-background);
    --smui-segment-ui-header-color: var(--smui-site-body-text-color);
    --smui-segment-border: 1px solid var(--slskd-tertiary-border-color);

    /* elements/header */
    --smui-header-text-color: var(--smui-site-body-text-color);

    /* view/card */
    --smui-card-background: var(--slskd-primary-background);
    --smui-card-border-shadow: 0px 0px 0px 1px #2b2b2a;
    --smui-card-floating-shadow: 0px 2px 4px 0px rgb(221, 219, 217 / 12%), 0px 2px 10px 0px rgb(221, 219, 217 / 15%);
    --smui-card-text-color: var(--smui-site-body-text-color);
    --smui-card-meta-color: var(--slskd-color-subtle);

    /* collections/table */
    --smui-table-background: var(--slskd-primary-background);
    --smui-table-color: var(--smui-site-body-text-color);
    --smui-table-border-color: var(--slskd-tertiary-background);
    --smui-table-header-background: var(--slskd-secondary-background);
    --smui-table-header-color: var(--smui-site-body-text-color);
    --smui-table-footer-background: var(--slskd-secondary-background);
    --smui-table-footer-color: var(--smui-site-body-text-color);
    --smui-table-selectable-background-color: var(--slskd-subtle-background);
    --smui-table-row-disabled-color: var(--slskd-color-subtle);
    --smui-table-row-negative-color: var(--smui-site-body-text-color);
    --smui-table-row-negative-background-color: rgba(248,81,73,0.15);
    --smui-table-row-negative-border-color:rgba(248,81,73,0.4);
    --smui-table-row-negative-background-hover-color:#da3633;
    --smui-table-row-warning-color: var(--smui-site-body-text-color);
    --smui-table-row-warning-background-color:rgba(187,128,9,0.15);
    --smui-table-row-warning-border-color: rgba(187,128,9,0.4);
    --smui-table-row-warning-background-hover-color: #9e6a03;

    /* modules/modal */
    --smui-modal-background: var(--slskd-overlay-background);
    --smui-modal-color: var(--smui-site-body-text-color);
    --smui-modal-border: 1px solid var(--slskd-tertiary-border-color);

    /* views/item */
    --smui-item-meta-color: var(--slskd-color-subtle);
    --smui-item-description-color: var(--slskd-color);

    /* modules/dropdown */
    --smui-menu-background: var(--slskd-hover-background);
    --smui-menu-item-color: var(--slskd-color);
    --smui-menu-hovered-item-background: var(--slskd-emphasis-background);
    --smui-menu-hovered-item-color: var(--slskd-color);

}

body {
    background-color: var(--background-color);
}

.ui.table td, th {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.ui.inverted.menu .item:before {
    background: rgba(255,255,255, 0);
}

.app {
    margin: auto;
    border: 0px !important;
    border-radius: 0% !important;
    box-shadow: none !important;
}

.pushable > .pusher.app-content {
    margin-top: 70px;
    background: var(--background-color);
}

.navigation {
    overflow: overlay;
}

.result-card {
    width: 100% !important;
}

.search-list-card {
    width: 100% !important;
}

.result-card:last-child, .transfer-card:last-child, .search-list-card:last-child {
    margin-bottom: 17px !important;
}

.result-meta {
    margin-top: 5px;
}

@media only screen and (min-width: 768px) {
    .transferlist-progress {
        max-width: 300px;
        width: 300px;
    }
}

@media only screen and (max-width: 767px) {
    .transferlist-progress {
        width: 100%;
    }
}

.filelist-header {
    margin-top: 20px !important;
}

.filelist-selector, .transferlist-selector, .list-selector {
    width: 40px;
}

.filelist-filename {
    word-wrap: break-word !important;
}

.filelist-size {
    width: 100px;
}

.filelist-attributes {
    width: 115px;
}

.filelist-length{
    width: 65px;
}

.showmore-button {
    padding: 20px !important;
    max-width: 1200px !important;
    margin-bottom: 17px !important;
}

.login-button {
    margin-top: 0px !important;
}

.login-failure {
    text-align: left;
}

.close-button {
    float: right;
    margin-right: 0px !important;
    font-size: 16pt !important;
    line-height: 1.15 !important;
}

.placeholder-segment, .placeholder-segment-small {
    opacity: .25;
}

.placeholder-segment-small {
    min-height: 6rem !important;
}

.placeholder-segment-small > .ui.icon.header {
    font-size: 1em !important;
}

.placeholder-segment-small > .ui.icon.header > i {
    font-size: 2em !important;
}


.menu-icon-group {
    font-size: 1.71428571em !important;
    margin: 0 auto .5rem !important;
    text-shadow: unset !important;
}

.menu-icon-no-shadow {
    font-size: 1rem !important;
    text-shadow: unset !important;
}

.loader-segment.ui.placeholder > :before {
    background-color: transparent !important;
}

.view {
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1200px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
  
.view:last-child {
  margin-bottom: 17px;
}

/* https://github.com/Semantic-Org/Semantic-UI-React/issues/3133#issuecomment-418766748 */
.ui.dimmer .ui.modal-loader.loader:before {
  border-color: rgba(0,0,0,.1);
}
        
.ui.dimmer .ui.modal-loader.loader:after {
  border-color: #767676 transparent transparent;
}